enum Events {
   CartItemAdded = "cart-item-added",
   CartItemRemoved = "cart-item-removed",
   CartItemUpdated = "cart-item-updated",
   UserLoggedIn = "user-logged-in",
   UserLoggedOut = "user-logged-out",
   UserRegister = "user-register",
   TokenUpdated = "token-updated",
   OrderCreated = "order-created",
   DomainDataModelTreeNodeNameChanged = "domain-data-model-tree-node-name-changed",
   DomainDataModelTreeNodeIconChanged = "domain-data-model-tree-node-icon-changed",
   DomainDataModelTreeNodeColorChanged = "domain-data-model-tree-node-color-changed",
   DomainDataModelTreeNodeUsageTypesChanged = "domain-data-model-tree-node-usageTypes-changed",
   DataModelTreeNodeCreated = "data-model-tree-node-created",
   DataModelTreeHideContentBricksChanged = "data-model-tree-hide-content-bricks-changed",
   DataModelTreeShowManuallyHiddenTreeNodesChanged = "data-model-tree-show-manually-hidden-tree-nodes-changed",
   DataModelTreeShowOnlyDglTreeNodesChanged = "data-model-tree-show-only-dgl-tree-nodes-changed",
   DataModelTreeShowFullTreeChanged = "data-model-tree-show-full-tree-changed",
   DomainChanged = "domain-changed",
   DisplayToast = "display-toast",
   SetMenuAdministrationEnabled = "set-menu-administration-enabled",
   AppInsightsTrack = "app-insights-track",
   DeactivateNotification = "deactivate-notification",
   DeactivateAllNotifications = "deactivate-all-notifications",
   DeactivateAllNotificationsList = "deactivate-all-notifications-list",
   DeactivateNotificationList = "deactivate-notification-list",
   ActivateNotification = "activate-notification",
   ReloadDomains = "reload-domains",
   ChangeDomain = "change-domain",
   ContentBrickUiDefinitionCopy = "content-brick-ui-definition-copy",
   LanguageChanged = "language-changed",
   OrganigramUsersChanged = "organigram-users-changed",
   UserUnavailabilityChanged = "unavailability-changed",
   GdmAutoNodeRemoveNodeProgressUpdated = "gdm-auto-remove-node-progress-updated",
   P2P = "peer-to-peer",
   ResizeEnd = "resize-end",
}

/**
 * Event data wrapper for P2P events
 */
export class PointToPointEventData<TValue> {
   /**
    * event ID
    */
   eId: string;
   /**
    * Event value/parameter
    */
   val: TValue | null;
}

export default Events;
