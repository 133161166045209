<template functional>
   <div :style="`width: ${props.width}`" class="d-inline-block">
      <div class="placeholder" :class="props.animation">
         <div :class="props.shape"></div>
      </div>
   </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

/**
 * Component used for rendering a placeholder object
 */
@Component({
   components: {},
})
export default class Placeholder extends Vue {
   /**
    * Placeholder width, in % or px - must specify explicitly
    */
   @Prop({ default: "100%" })
   width: string;

   /**
    * Placeholder shape: 'line', 'circle', 'square'
    */
   @Prop({ default: "line" })
   shape: string;

   /**
    * Type of animation: 'pulse', 'wave' or ''
    */
   @Prop({ default: "" })
   animation: string;
}
</script>
<style lang="scss" scoped>
// Placeholder styles with effects from https://stackoverflow.com/a/51377123
.placeholder {
   width: 100%;
   height: 100%;
   display: inline;
   align-content: center;
   justify-content: center;
}

.placeholder div {
   background: #e8e8e8;
}

.placeholder .square {
   float: left;
   width: 90px;
   height: 56px;
   margin: 0 0 10px;
}

.placeholder .line {
   height: 12px;
   width: 100%;
   border-radius: 2px;
}

.placeholder .circle {
   float: left;
   width: 15px;
   height: 15px;
   margin: 0 15px 10px 0;
   border-radius: 15px;
}

/* 
  --------------
  Pulse effect animation 
  Activated by adding a '.pulse' class to the placeholder
  --------------
*/

.placeholder.pulse div {
   animation: pulse 1s infinite ease-in-out;
   -webkit-animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
   0% {
      background-color: rgba(165, 165, 165, 0.1);
   }
   50% {
      background-color: rgba(165, 165, 165, 0.3);
   }
   100% {
      background-color: rgba(165, 165, 165, 0.1);
   }
}

@-webkit-keyframes pulse {
   0% {
      background-color: rgba(165, 165, 165, 0.1);
   }
   50% {
      background-color: rgba(165, 165, 165, 0.3);
   }
   100% {
      background-color: rgba(165, 165, 165, 0.1);
   }
}

/* 
  --------------
  Wave effect animation 
  Activated by adding a '.wave' class to the placeholder
  --------------
*/

.placeholder.wave div {
   animation: wave 1s infinite linear forwards;
   -webkit-animation: wave 1s infinite linear forwards;
   background: #f6f7f8;
   background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
   background-size: 800px 104px;
}

@keyframes wave {
   0% {
      background-position: -468px 0;
   }
   100% {
      background-position: 468px 0;
   }
}

@-webkit-keyframes wave {
   0% {
      background-position: -468px 0;
   }
   100% {
      background-position: 468px 0;
   }
}
</style>
