<template>
   <div>
      <spinner v-if="loading" />
      <v-container v-else fluid>
         <detail-header
            v-if="contentBrick"
            :entity="contentBrick"
            :title="title"
            :isNew="isNew"
            :deleteFunc="deleteContentBrickDefinition"
            :originalStatus="originalStatus"
            :isFromAdministration="false"
            :currentTab="selectedTab"
            :canDelete="canDelete"
            :canChangeStatus="canEdit"
         ></detail-header>
         <v-row>
            <v-col>
               <div class="detail-tab-menu-header-container" elevation="3">
                  <v-tabs
                     color="error"
                     v-model="selectedTab"
                     centered
                     icons-and-text
                     background-color="rgb(245,245,245)"
                     :key="updateTabBar"
                     show-arrows
                  >
                     <v-tabs-slider></v-tabs-slider>
                     <v-tab
                        v-for="tab in detailTabItems"
                        :key="tab.id"
                        :disabled="!isTabEnabled(tab.id)"
                        v-on="tab.onActivated ? { change: tab.onActivated } : {}"
                     >
                        {{ tab.title }}
                        <v-icon color="red">{{ tab.icon }}</v-icon>
                     </v-tab>
                  </v-tabs>
                  <v-toolbar dense floating v-if="contentBrick">
                     <back-btn fallback-route="/contentBricks/overview"></back-btn>
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!canSave()"
                              v-shortkey.once="['ctrl', 's']"
                              @shortkey="upsertContentBrickDefinition()"
                              @click="upsertContentBrickDefinition()"
                           >
                              <v-icon dense color="success">mdi-content-save</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.saveTooltip") }}</span>
                     </v-tooltip>
                     <create-new-item-btn
                        v-if="canCreate"
                        :new-item-route="newItemRoute"
                        :tooltip-title="label"
                        :translations="routeTranslations"
                     />
                     <v-tooltip top v-if="!isNew && existsActiveVersion">
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn icon v-bind="attrs" v-on="on" @click="showDdmNodeSelectionDialog">
                              <v-icon dense color="cyan">mdi-database-outline</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.addToDomainModelTooltip") }}</span>
                     </v-tooltip>
                     <v-tooltip top v-if="!isNew && existsActiveVersion">
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn icon v-bind="attrs" v-on="on" @click="showGdmNodeSelectionDialog">
                              <v-icon dense color="cyan">mdi-bookshelf</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.addToGeneralModelTooltip") }}</span>
                     </v-tooltip>
                     <v-tooltip top v-if="canCreateDraft">
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              v-shortkey.once="['ctrl', 'shift', 'c']"
                              @shortkey="createContentBrickDefinitionCopy"
                              @click="createContentBrickDefinitionCopy"
                           >
                              <v-icon dense color="cyan">mdi-content-copy</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.newCopyTooltip") }}</span>
                     </v-tooltip>
                     <v-tooltip top v-if="canCreateDraft">
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              v-shortkey.once="['ctrl', 'd']"
                              @shortkey="createContentBrickDefinitionDraft()"
                              @click="createContentBrickDefinitionDraft()"
                           >
                              <v-icon dense color="success">mdi-content-duplicate</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.newDraftTooltip") }}</span>
                     </v-tooltip>
                     <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                           <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              v-shortkey.once="['ctrl', 'p']"
                              @shortkey="exportPdf()"
                              @click="exportPdf()"
                              :loading="exporting"
                           >
                              <v-icon dense color="success">mdi-file-export-outline</v-icon>
                           </v-btn>
                        </template>
                        <span>{{ translateKey("detailView.exportTooltip") }}</span>
                     </v-tooltip>
                  </v-toolbar>
               </div>
               <v-tabs-items v-if="contentBrick" v-model="selectedTab">
                  <v-tab-item>
                     <v-card>
                        <v-form ref="contentForm">
                           <content-brick-detail-tab-content
                              ref="contentTab"
                              :contentBrick="contentBrick"
                              :contentBrickType="cbType"
                              :isNew="isNew"
                              :hasChanges="hasChanges"
                              :originalStatus="originalStatus"
                              v-on:disableRouteGuard="disableRouteGuard"
                              :translations="routeTranslations"
                              :attachments="dictAttachments"
                              :attachmentsLoading="attachmentsLoading"
                              @attachment-modified="onAttachmentModified"
                              @mediaUrlsUpdated="onMediaUrlsUpdated"
                           ></content-brick-detail-tab-content>
                        </v-form>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item v-if="hasValueFields">
                     <v-card>
                        <design-guideline-simple-view
                           :designGuideline="contentBrick"
                           :translations="routeTranslations"
                           :attachments="dictAttachments"
                        ></design-guideline-simple-view>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card>
                        <content-brick-detail-tab-script
                           ref="scripts"
                           :contentBrickId="contentBrick.id"
                           :disabled="!canEdit"
                           @hasChangesChanged="onScriptsChanged"
                           :translations="routeTranslations"
                        ></content-brick-detail-tab-script>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item v-if="!hasValueFields">
                     <v-card>
                        <document-in-pbbs-usage
                           :documentId="contentBrick.id"
                           :documentType="documentType"
                           :translations="routeTranslations"
                        ></document-in-pbbs-usage>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <v-card>
                        <content-brick-detail-tab-data-model-usage
                           :contentBrick="contentBrick"
                           :translations="routeTranslations"
                        ></content-brick-detail-tab-data-model-usage>
                     </v-card>
                  </v-tab-item>
                  <v-tab-item>
                     <version-table-tab
                        :versions="contentBrick.availableVersions"
                        :translations="routeTranslations"
                     ></version-table-tab>
                  </v-tab-item>
                  <v-tab-item v-if="hasValueFields">
                     <design-guideline-detail-tab-reports
                        :attachments="dictAttachments"
                        :dglId="id"
                        :title="translateKey('reportsTab.titleLabel')"
                        :translations="routeTranslations"
                     ></design-guideline-detail-tab-reports>
                  </v-tab-item>
                  <v-tab-item>
                     <content-brick-detail-tab-related-content-bricks
                        :contentBrick="contentBrick"
                        :translations="routeTranslations"
                     ></content-brick-detail-tab-related-content-bricks>
                  </v-tab-item>
               </v-tabs-items>
            </v-col>
         </v-row>
      </v-container>
      <confirm-close-dialog-async ref="confirmCloseDialog"></confirm-close-dialog-async>
      <version-note-dialog ref="versionNoteDialog"></version-note-dialog>
      <data-model-node-selection-dialog
         :showDialog="isDataModelNodeSelectionDialogShown"
         :show-data-model-selection="true"
         :is-domain-data-model="isDomainDataModel"
         :multiple="false"
         title="Choose a node from the Data Model"
         @hideDialog="hideDataModelNodeSelectionDialog"
         @dataModelNodeSelected="onDataModelNodeSelected"
         :selectableNodeTypes="[DataModelNodeType.Container]"
      ></data-model-node-selection-dialog>
      <export-pdf-design-guidline
         :contentBricks="contentBrickAsArray"
         :hasValueFields="hasValueFields"
         class="d-none"
         ref="exportContainer"
         v-if="isExportDialogShown"
         @export-done="onExportDone"
         :mediaUrls="mediaUrls"
      ></export-pdf-design-guidline>
   </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as moment from "moment";

import Spinner from "@components/Shared/spinner.vue";
import VersionNoteDialog from "@components/Shared/version-note-dialog.vue";
import ConfirmCloseDialogAsync from "@components/Shared/confirm-close-dialog-async.vue";
import ChangeWatcher from "@utils/ChangeWatcher";
import DetailHeader from "@components/Shared/detail-header.vue";
import VersionTableTab from "@components/Shared/version-table-tab.vue";
import ContentBrickDetailTabContent from "@components/ContentBricks/TabDetail/content-brick-detail-tab-content.vue";
import DocumentInPbbsUsage from "@components/PBB/Shared/document-in-pbbs-usage.vue";
import ContentBrickDetailTabDataModelUsage from "@components/ContentBricks/TabDetail/content-brick-detail-tab-data-model-usage.vue";
import ContentBrickDetailTabScript from "@components/ContentBricks/TabDetail/content-brick-detail-tab-script.vue";
import BackBtn from "@components/Shared/back-btn.vue";
import CreateNewItemBtn from "@components/Shared/create-new-item-btn.vue";

import BaseResponse from "@models/BaseResponse";
import GlobalStore from "@backend/store/globalStore.ts";
import { EntityStatusDecorator } from "@models/shared/EntityStatusDecorator";
import {
   ContentBrickDefinitionApi,
   ContentBrickDefinition,
   IContentBrickDefinition,
   EntityStatus,
   VersionedDocumentStatusChange,
   UiDefinitionType,
   EntityType,
   ContentBrickConditionResultsIn,
   ContentBrickType,
   ContentBrickExpression,
   ContentBrickExpressionType,
   AttachmentMetadata,
   DataModelNodeType,
   IItemReference,
   LibraryDataModelApi,
   DomainDataModelApi,
   ContentBrickFieldGroupDefinition,
   Domain,
   ContentBrickFieldDefinition,
   DesignGuidelineValueFieldDefinition,
} from "@backend/api/pmToolApi";
import Events from "@models/shared/Events";
import EventBus from "@backend/EventBus";
import filterStore from "@backend/store/filterStore";
import DomUtils from "@utils/DomUtils";
import IDataModelNodePair from "@models/dataModel/IDataModelNodePair";
import DetailBase from "@components/Shared/Base/detail-base.vue";
import DesignGuidelineDetailTabReports from "@components/ContentBricks/TabDetail/design-guideline-detail-tab-reports.vue";
import ContentBrickDetailTabRelatedContentBricks from "@components/ContentBricks/TabDetail/content-brick-detail-tab-related-content-bricks.vue";
import DataModelNodeSelectionDialog from "@components/PBB/DomainDataModels/Shared/data-model-node-selection-dialog.vue";
import ExportPdfDesignGuidline from "@components/ContentBricks/Export/export-pdf-design-guideline.vue";
import AttachmentUtils from "@utils/AttachmentUtils";
import DomainCachedApi from "@backend/store/apiCache/DomainCachedApi";
import { Guid } from "guid-typescript";
import DesignGuidelineSimpleView from "@components/ContentBricks/TabDetail/design-guideline-simple-view.vue";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import ContentBrickUtils from "@utils/ContentBrickUtils";

@Component({
   name: "ContentBrickDetail",
   components: {
      Spinner,
      VersionNoteDialog,
      ConfirmCloseDialogAsync,
      DetailHeader,
      ContentBrickDetailTabContent,
      DocumentInPbbsUsage,
      ContentBrickDetailTabDataModelUsage,
      ContentBrickDetailTabScript,
      VersionTableTab,
      BackBtn,
      CreateNewItemBtn,
      DesignGuidelineDetailTabReports,
      ExportPdfDesignGuidline,
      DataModelNodeSelectionDialog,
      DesignGuidelineSimpleView,
      ContentBrickDetailTabRelatedContentBricks,
   },
})
export default class ContentBrickDetail extends DetailBase {
   id: string = "";
   loading: boolean = false;
   isNew: boolean = false;

   contentBrick: IContentBrickDefinition | null = null;
   originalStatus: EntityStatus | null = null;
   UiDefinitionType: any = UiDefinitionType;
   EntityStatus: any = EntityStatus;
   DataModelNodeType: any = DataModelNodeType;
   routeNames: string[] = [
      "content-brick-detail",
      "content-brick-field-type-decorator",
      "content-brick-tree-node-type-decorator",
      "content-brick-script-type-decorator",
      "content-brick-type-decorator",
      "detail-view",
      "task-detail.test-report-dialog",
      "design-guideline-result-type-decorator",
      "design-guideline-field-evaluation-decorator",
      "enactment-priority-label-overview",
   ];

   exporting: boolean = false;
   isExportDialogShown: boolean = false;

   get detailTabs(): any[] {
      return [
         { id: 0, icon: "mdi-details", title: "Content", translationKey: "contentBrickDetail.contentTab" },
         {
            id: 1,
            icon: "mdi-card-text-outline",
            title: "Simple View",
            translationKey: "contentBrickDetail.simpleView",
         },
         {
            id: 2,
            icon: "mdi-script-text-outline",
            title: "Scripts",
            hasChanges: false,
            translationKey: "contentBrickDetail.scriptsTab",
         },
         {
            id: 3,
            icon: "mdi-debug-step-into",
            title: "PBB References",
            translationKey: "contentBrickDetail.pbbReferencesTab",
         },
         {
            id: 4,
            icon: "mdi-debug-step-into",
            title: "Data Model References",
            translationKey: "contentBrickDetail.dataModelReferencesTab",
         },
         { id: 6, icon: "mdi-history", title: "History", translationKey: "contentBrickDetail.historyTab" },
         {
            id: 7,
            icon: "mdi-cube-scan",
            title: "Project References",
            translationKey: "contentBrickDetail.projectReferencesTab",
         },
         {
            id: 8,
            icon: ContentBrickTypeDecorator.get(this.cbType).icon,
            title: this.relatedTabTitle,
            translationKey: ContentBrickUtils.relatedTabTranslationKeyMap.get(this.cbType),
         },
      ];
   }

   get relatedTabTitle(): string {
      const decorator = ContentBrickTypeDecorator.get(this.cbType);
      return decorator.isPlural ? decorator.legacyLabel : `${decorator.legacyLabel}s`;
   }

   get draftId(): string | undefined {
      return this.contentBrick?.version?.draftId;
   }

   get contentBrickAsArray(): IContentBrickDefinition[] {
      return this.contentBrick ? [this.contentBrick] : [];
   }

   @Prop({ default: ContentBrickType.ContentBrick })
   cbType: ContentBrickType;

   @Watch("selectedTab")
   onSelectedTabChanged(newValue, oldValue) {
      // force close form-builder right sidebar by saving first
      if (oldValue == 4 || oldValue == 5) {
         var elems = document.querySelectorAll("button.btn.btn-info.md-button.md-raised.md-info.md-theme-default");
         elems.forEach((el) => {
            if (el.textContent === " Save & Close ") {
               el.click();
            }
         });

         // force close form-builder right sidebar by closing (some sidebars only have close button)
         var closeElems = document.querySelectorAll("span.close");
         closeElems.forEach((el) => {
            el.click();
         });
      }
   }

   @Watch("contentBrick.fields", { deep: true })
   async onFieldsChanged(
      newValue: ContentBrickFieldDefinition[] | undefined,
      oldValue: ContentBrickFieldDefinition[] | undefined
   ) {
      // only modification of existing members
      if (newValue == oldValue && newValue?.length === oldValue?.length) await this.validateAsync(false);
   }

   @Watch("contentBrick.designGuidelineValueFields", { deep: true })
   async onDesignGuidelineChanged(
      newValue: DesignGuidelineValueFieldDefinition[] | undefined,
      oldValue: DesignGuidelineValueFieldDefinition[] | undefined
   ) {
      // only modification of existing members
      if (newValue == oldValue && newValue?.length === oldValue?.length) await this.validateAsync(false);
   }

   get documentType(): EntityType {
      return EntityType.ContentBrickDefinition;
   }

   get title(): string {
      return this.isNew ? `New ${this.label}` : this.contentBrick?.name ?? `Unknown ${this.label}`;
   }

   canSave(): boolean {
      if (!this.isNew && this.contentBrick?.permissions?.update !== true) {
         return false;
      }

      if (this.originalStatus !== this.contentBrick.entityStatus) {
         return true;
      }

      if (this.originalStatus !== EntityStatus.Draft) {
         return false;
      }

      return (
         this.hasChanges ||
         this.detailTabs[2].hasChanges ||
         (this.isNew && this.contentBrick.copiedFrom) /* if initiated from template*/
      );
   }

   get canEdit(): boolean {
      return (
         this.originalStatus === EntityStatus.Draft && (this.isNew || this.contentBrick?.permissions?.update === true)
      );
   }

   get canCreateDraft(): boolean {
      return (
         !this.isNew && this.originalStatus !== EntityStatus.Draft && this.contentBrick?.permissions?.update === true
      );
   }

   get canCreate(): boolean {
      return this.isNew || this.contentBrick?.permissions?.create === true;
   }

   get canDelete(): boolean {
      return !this.isNew && this.contentBrick?.permissions?.delete === true;
   }

   get AllStates(): EntityStatusDecorator[] {
      return EntityStatusDecorator.AllItems;
   }

   get hasValueFields() {
      return ContentBrickTypeDecorator.get(this.cbType).hasValueFields;
   }

   get existsActiveVersion(): boolean {
      if (!this.contentBrick) return false;

      // true if current contentBrick is active or entity has any active version
      return this.originalStatus === EntityStatus.Active ||
         this.contentBrick.availableVersions?.find((x) => x.entityStatus == EntityStatus.Active)
         ? true
         : false;
   }

   get label() {
      return this.translateKey(ContentBrickTypeDecorator.get(this.cbType).translationKey);
   }

   get newItemRoute() {
      return this.$router.resolve({ name: ContentBrickTypeDecorator.get(this.cbType).newItemRouteName }).href;
   }

   formatTitleDate(date: moment.Moment | null): string {
      return date?.format("l LT") ?? "";
   }

   async upsertContentBrickDefinition(isNavigating: boolean = false): Promise<void> {
      let versionNote: string = null;

      if (this.isNew) {
         await this.createContentBrickDefinition(isNavigating, versionNote);
      }
      // status changed to 'Draft' on non-draft item, create new draft instead of saving
      else if (this.contentBrick.entityStatus === EntityStatus.Draft && this.originalStatus !== EntityStatus.Draft) {
         await this.createContentBrickDefinitionDraft(isNavigating);
      } else {
         await this.updateContentBrickDefinition(versionNote);
      }
   }

   // -------- Save guard -------------
   hasChanges: boolean = false;

   isRouteGuarded: boolean = true;

   get hasDesignGuidelineAttachementChange() {
      if (this.hasValueFields) {
         this.contentBrick.designGuidelineValueFields?.filter(
            (x) => AttachmentUtils.isAttachmentField(x.type) && this.or
         );
      }
      return false;
   }

   async beforeRouteLeave(to, from, next): Promise<void> {
      await ChangeWatcher.onRouteChange(next, this, () => this.upsertContentBrickDefinition(true), to);
   }

   async beforeRouteUpdate(to, from, next): Promise<void> {
      await ChangeWatcher.onRouteChange(next, this, () => this.upsertContentBrickDefinition(true), to);
   }

   disableRouteGuard() {
      this.isRouteGuarded = false;
   }

   // -------- Tabs control -------------
   selectedTab: number = 0;

   get detailTabItems() {
      var result = this.detailTabs;
      if (this.hasValueFields) {
         result = this.detailTabs.filter((x) => x.id != 3);
      } else {
         result = this.detailTabs.filter((x) => x.id != 1 && x.id != 7);
      }
      return result;
   }

   isTabEnabled(tabId: number): boolean {
      return tabId === 0 || !this.isNew;
   }

   onScriptsChanged(hasChanges: boolean): void {
      this.detailTabs[2].hasChanges = hasChanges;
   }

   //--------- FormBuilder UI -----------
   updateTabBar: number = 1;

   onFormBuilderSideBarToggled() {
      // FormBuilder sidebar sets/unsets margin on body element -> vuetify is not aware of this change -> need to trigger re-render tab bar manually
      this.renderTabBar();
   }

   onFormBuilderSideBarOpened() {
      // FormBuilder sidebar needs to get fully loaded -> wait until the DOM is rendered
      Vue.nextTick().then(this.hideUnwantedAddControlButtons);
   }

   renderTabBar() {
      this.updateTabBar += 1;
   }

   hideUnwantedAddControlButtons() {
      var buttonNames = ["File Uploader", "Checkbox List", "Radio List", "Button", "Date Picker"];
      for (var buttonName of buttonNames) {
         this.hideAddControlButton(buttonName);
      }
   }

   hideAddControlButton(name: string) {
      var controlXpath = `//p[text()='${name}']/parent::a`; // <a> parent tag of <p> with text provided from variable 'name'
      DomUtils.removeElementByXpath(controlXpath);
   }

   //--------- Add to DDM/GDM -----------
   isDataModelNodeSelectionDialogShown: boolean = false;
   isDomainDataModel: boolean = false;

   async onDataModelNodeSelected(selectedNodes: IDataModelNodePair[]) {
      if (selectedNodes?.length != 1) {
         throw `Unexpected number of selected Models: ${selectedNodes?.length}`;
      }

      this.hideDataModelNodeSelectionDialog();
      if (this.isDomainDataModel) {
         await this.addContentBrickToDomainDataModel(selectedNodes[0].dataModel, selectedNodes[0].dataModelNode.id);
      } else {
         await this.addContentBrickToGeneralDataModel(selectedNodes[0].dataModel, selectedNodes[0].dataModelNode.id);
      }
   }

   hideDataModelNodeSelectionDialog() {
      this.isDataModelNodeSelectionDialogShown = false;
   }

   showDdmNodeSelectionDialog() {
      this.isDomainDataModel = true;
      this.isDataModelNodeSelectionDialogShown = true;
   }

   showGdmNodeSelectionDialog() {
      this.isDomainDataModel = false;
      this.isDataModelNodeSelectionDialogShown = true;
   }

   notifyError(error: BaseResponse, actionName: string, subjectName: string = this.label) {
      if (error.errors && Object.keys(error.errors).length > 0) {
         for (var prop in error.errors) {
            EventBus.$emit(Events.DisplayToast, {
               color: "error",
               text: `Failed to ${actionName} ${subjectName}: ${error.errors[prop]}`,
            });
         }
      } else {
         EventBus.$emit(Events.DisplayToast, {
            color: "error",
            text: `Failed to ${actionName} ${subjectName}: ${error.message}`,
         });
      }
   }

   // ---------- Validation ------------
   async validateAsync(scrollToError: boolean = true): Promise<boolean> {
      // Content
      const moduleResult = this.$refs.contentTab.validate();
      await this.$nextTick(); // module validation may have expanded rows, which need to be visually validated
      const vueResult = this.$refs.contentForm.validate();
      if (scrollToError && (!vueResult || !moduleResult)) {
         this.$nextTick(() => {
            const el = this.$refs.contentForm.$el?.querySelector(".v-messages.error--text:first-of-type");
            el?.scrollIntoView({ behavior: "smooth", block: "center" });
         });
         return false;
      }

      return true;
   }

   // ---- API -----
   setContentBrick(contentBrick: ContentBrickDefinition) {
      this.contentBrick = contentBrick;
      this.originalStatus = contentBrick.entityStatus;
      this.updateWindowTitle(`${contentBrick.code}`);
      GlobalStore.setDomain(contentBrick.domain);
      EventBus.$emit(Events.ChangeDomain);
   }

   get detailName() {
      return ContentBrickTypeDecorator.get(this.cbType).detailRouteName;
   }

   async loadContentBrickDefinition(): Promise<void> {
      this.loading = true;
      try {
         // Call the API
         let contentBrick = await ContentBrickDefinitionApi.getContentBrickDefinition(this.id);

         if (ContentBrickTypeDecorator.get(contentBrick.type).detailRouteName !== this.$route.name) {
            throw { message: `Unexpected route ${this.$route.name} for Content Brick type ${contentBrick.type}` };
         }

         // Process/Save data etc.
         this.setContentBrick(contentBrick);
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition load error:", error);
         this.notifyError(error, "load", "ContentBrickDefinition");
      }
      this.loading = false;
   }

   async createContentBrickDefinition(isNavigating: boolean = false, versionNote?: string | null): Promise<void> {
      if (this.contentBrick.entityStatus != EntityStatus.Draft) {
         if (!(await this.validateAsync())) {
            this.notifyError({ message: "Validation failed.", success: false }, "create");
            return;
         }
      }

      this.loading = true;
      try {
         // Call the API
         let contentBrick = await ContentBrickDefinitionApi.createContentBrickDefinition(
            this.contentBrick,
            versionNote
         );
         // Process/Save data etc.
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: `${this.label} has been created`,
         });
         if (!isNavigating) {
            this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
            this.$router.replace({
               name: this.detailName,
               params: {
                  id: contentBrick.id,
               },
            });
         }
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition create error:", error);
         this.notifyError(error, "create", "ContentBrickDefinition");
      }
      this.loading = false;
   }

   async createContentBrickDefinitionDraft(isNavigating: boolean = false): Promise<void> {
      if (this.draftId != null) {
         this.notifyInfo("Draft is already created.");
         this.$router.push({
            name: this.detailName,
            params: {
               id: this.draftId,
               restoreTab: this.selectedTab,
            },
         });
         return;
      }

      this.loading = true;
      try {
         this.contentBrick.type = this.cbType;
         // Call the API
         let contentBrick = await ContentBrickDefinitionApi.createContentBrickDefinitionDraft(this.contentBrick.id);
         // Process/Save data etc.
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: `${this.label} draft has been created`,
         });
         if (!isNavigating) {
            this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
            this.$router.replace({
               name: this.detailName,
               params: {
                  id: contentBrick.id,
                  restoreTab: this.selectedTab,
               },
            });
         }
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition create draft error:", error);

         if (error.message === "Invalid argument") {
            EventBus.$emit(Events.DisplayToast, {
               color: "error",
               text: `Cannot create new draft - a draft for ${this.label} {${this.contentBrick.code}} already exists.`,
            });
            // Cannot create new draft - a draft for Content Brick {{ contentBrick.code }} already exists.
         } else {
            this.notifyError(error, "update", `${this.label} draft`);
         }
      }
      this.loading = false;
   }

   async createContentBrickDefinitionCopy(): Promise<void> {
      this.loading = true;
      try {
         this.contentBrick.type = this.cbType;
         // Call the API
         let contentBrick = await ContentBrickDefinitionApi.createContentBrickDefinitionCopy(this.contentBrick.id);
         // Process/Save data etc.
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: `${this.label} copy has been created`,
         });

         this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
         this.$router.replace({
            name: this.detailName,
            params: {
               id: contentBrick.id,
               // restoreTab: this.selectedTab, switch back to the default tab
            },
         });
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition create copy error:", error);
         this.notifyError(error, "update", `${this.label} copy`);
      }
      this.loading = false;
   }

   async addContentBrickToDomainDataModel(ddm: IItemReference, ddmNodeId: string): Promise<void> {
      if (!this.contentBrick?.code) throw "Content Brick code required";
      if (!ddm?.code) throw "Domain Model code required";

      this.loading = true;

      try {
         await DomainDataModelApi.addContentBrickToNewVersion(ddm.code, ddmNodeId, this.contentBrick.code);

         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: `${this.label} has been added to '${ddm.code} ${ddm.displayText}'`,
         });
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition add to DDM error:", error);
         this.notifyError(error, "add", "ContentBrick definition to DDM");
      }

      this.loading = false;
   }

   async addContentBrickToGeneralDataModel(gdm: IItemReference, gdmNodeId: string): Promise<void> {
      if (!this.contentBrick?.code) throw "Content Brick code required";
      if (!gdm?.code) throw "General Model code required";

      this.loading = true;
      try {
         await LibraryDataModelApi.addContentBrickToNewVersion(gdm.code, gdmNodeId, this.contentBrick.code);

         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: `${this.label} has been added to GDM '${gdm.code} ${gdm.displayText}'`,
         });
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition add to GDM error:", error);
         this.notifyError(error, "add", "ContentBrick definition to GDM");
      }

      this.loading = false;
   }

   async updateContentBrickDefinition(versionNote?: string | null): Promise<void> {
      if (!(await this.validateAsync())) {
         this.selectedTab = 0;
         this.notifyError({ message: "Validation failed.", success: false }, "update");
         return;
      }

      // status changed 'Draft' -> non-draft
      if (
         (this.originalStatus === EntityStatus.Draft || this.isNew) &&
         this.contentBrick.entityStatus !== EntityStatus.Draft
      ) {
         if (this.contentBrick.version?.major > 1) {
            //if not the first version
            let res = await this.$refs.versionNoteDialog.show();
            if (!res.result) {
               return;
            }
            versionNote = res.versionNote;
         } else {
            versionNote = "Initial version"; // add some default value
         }
      }

      if (this.detailTabs[2].hasChanges) {
         await this.$refs.scripts.saveAsync();
      }

      this.loading = true;

      if (this.hasChanges) {
         try {
            // Call the API
            let contentBrick: ContentBrickDefinition = null;

            this.contentBrick.type = this.cbType;
            if (this.originalStatus === EntityStatus.Draft) {
               //if status was Draft, save it, otherwise skip saving (discard all changes)
               contentBrick = await ContentBrickDefinitionApi.updateContentBrickDefinition(this.id, this.contentBrick);
            }

            if (this.contentBrick.entityStatus !== this.originalStatus) {
               //if status changed update status-only in separate step
               contentBrick = await ContentBrickDefinitionApi.updateContentBrickDefinitionStatus(
                  this.id,
                  new VersionedDocumentStatusChange({
                     newStatus: this.contentBrick.entityStatus,
                     versionNote: versionNote,
                  })
               );
            }

            if (contentBrick) {
               // if something was saved
               if (contentBrick.id !== this.id) {
                  this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
                  this.$router.replace({
                     name: this.detailName,
                     params: {
                        id: contentBrick.id,
                        restoreTab: this.selectedTab,
                     },
                  }); // new copy was created
               }

               // Process/Save data etc.
               this.setContentBrick(contentBrick);
               EventBus.$emit(Events.DisplayToast, {
                  color: "success",
                  text: `${this.label} has been updated`,
               });
            }
         } catch (e) {
            let error = e as BaseResponse;
            console.log("API ContentBrickDefinition update error:", error);
            this.notifyError(error, "update", "ContentBrickDefinition");
         }
      }

      this.loading = false;
   }

   async deleteContentBrickDefinition(): Promise<void> {
      this.loading = true;
      try {
         if (!this.contentBrick) throw "No ContentBrickDefinition defined";

         await ContentBrickDefinitionApi.deleteContentBrickDefinition(this.contentBrick.id);
         EventBus.$emit(Events.DisplayToast, {
            color: "success",
            text: `${this.label} has been deleted`,
         });
         this.isRouteGuarded = false; // turn guards off in order to prevent infinite loop
         this.$router.replace({ name: ContentBrickTypeDecorator.get(this.cbType).listRouteName });
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API ContentBrickDefinition delete error:", error);
         this.notifyError(error, "delete", "ContentBrickDefinition");
      }
      this.loading = false;
   }
   //---------------Attachments----------///
   attachments: AttachmentMetadata[] = [];
   dictAttachments: { [key: string]: AttachmentMetadata } = {};
   attachmentsLoading: Boolean = false;
   async loadAttachments() {
      if (this.contentBrick && this.contentBrick.hasAttachments) {
         try {
            this.attachmentsLoading = true;
            this.attachments = await ContentBrickDefinitionApi.getContentBrickAttachments(this.id);
            this.attachments.forEach((item) => {
               this.dictAttachments[item.blobName!] = item;
            });
         } catch (e) {
            let error = e as BaseResponse;
            console.log("API ContentBrickDefinition get attachments error:", error);
            this.notifyError(error, "load", "attachments");
         } finally {
            this.attachmentsLoading = false;
         }
      } else {
         this.attachments = [];
         this.dictAttachments = {};
      }
   }

   onAttachmentModified(hadChanges: boolean) {
      if (!hadChanges) {
         this.setContentBrick(new ContentBrickDefinition({ ...this.contentBrick! }));
      }
   }

   mediaUrls: { [key: string]: string } = {};
   onMediaUrlsUpdated(mediaUrls: { [key: string]: string }) {
      this.mediaUrls = mediaUrls;
   }

   exportPdf() {
      this.exporting = true;
      this.isExportDialogShown = true;
   }
   onExportDone() {
      this.exporting = false;
      this.isExportDialogShown = false;
   }

   async initializeNewEntity(): Promise<ContentBrickDefinition> {
      const status = EntityStatus.Draft;

      let domainId = GlobalStore.getDomain();

      if (
         this.$route.name === "design-guideline-detail-new" ||
         this.$route.name === "practical-test-detail-new" ||
         this.$route.name === "general-test-detail-new" ||
         this.$route.name === "norms-and-standards-detail-new" ||
         this.$route.name === "material-compliance-detail-new" ||
         this.$route.name === "pre-shipment-instructions-detail-new"
      ) {
         try {
            this.loading = true;
            const domain = await DomainCachedApi.getDomainByDomainId(domainId);
            const templateCode = this.getTemplateCode(domain);

            if (templateCode) {
               const template = await ContentBrickDefinitionApi.getContentBrickDefinitionByCode(templateCode);

               return ContentBrickDefinition.fromJS({
                  ...template, // use all template values
                  entityStatus: status, // except...
                  id: undefined,
                  code: undefined,
                  name: undefined,
                  identifier: undefined,
                  created: undefined,
                  lastModified: undefined,
                  version: undefined,
                  availableVersions: undefined,
                  copiedFrom: template.id,
                  contentBrickFieldGroupTree:
                     template.contentBrickFieldGroupTree ??
                     new ContentBrickFieldGroupDefinition({
                        id: Guid.create().toString(),
                        groupId: Guid.EMPTY,
                        name: "Root",
                        identifier: "root",
                        order: 0,
                        children: [],
                        description: undefined,
                        condition: undefined,
                        visibilityCondition: undefined,
                     }),
               });
            }
         } catch (e) {
            let error = e as BaseResponse;
            console.log("API get ContentBrickDefinition by Domain template:", error);
            this.notifyError(error, "load", "ContentBrickDefinition by Domain template");
            throw e;
         } finally {
            this.loading = false;
         }
      }

      return ContentBrickDefinition.fromJS({
         entityStatus: status,
         domain: domainId,
         fields: [],
         designGuidelineValueFields: [],
         type: this.cbType,
         saveOnlyInPdm: this.hasValueFields,
         contentBrickConditionResultsIn: ContentBrickConditionResultsIn.Error,
         condition: new ContentBrickExpression({
            expressionType: ContentBrickExpressionType.LogicGroupExpression,
            operator: "AND",
            operands: [],
            left: undefined,
            right: undefined,
         }),
         contentBrickFieldGroupTree: new ContentBrickFieldGroupDefinition({
            id: Guid.create().toString(),
            groupId: Guid.EMPTY,
            name: "Root",
            identifier: "root",
            order: 0,
            children: [],
            description: undefined,
            condition: undefined,
            visibilityCondition: undefined,
         }),
      });
   }

   getTemplateCode(domain: Domain) {
      if (this.$route.name === "design-guideline-detail-new") {
         return domain?.designGuidelineTemplate?.code;
      } else if (this.$route.name === "practical-test-detail-new") {
         return domain?.practicalTestTemplate?.code;
      } else if (this.$route.name === "general-test-detail-new") {
         return domain?.generalTestTemplate?.code;
      } else if (this.$route.name === "norms-and-standards-detail-new") {
         return domain?.normsAndStandardsTemplate?.code;
      } else if (this.$route.name === "material-compliance-detail-new") {
         return domain?.materialComplianceTemplate?.code;
      } else if (this.$route.name === "pre-shipment-instructions-detail-new") {
         return domain?.preShipmentInstructionsTemplate?.code;
      }

      throw "Invalid/unknown template type";
   }

   async loadTranslations() {
      await this.loadRouteTranslations(this.routeNames);
      for (var tab of this.detailTabs) {
         tab.title = this.translateKey(tab.translationKey);
      }
   }

   async mounted() {
      this.$root.isDomainChangeDisabled = true;
      this.id = this.$route.params.id;
      this.isNew = !!!this.id;

      this.loadTranslations();
      EventBus.$on(Events.LanguageChanged, () => {
         this.loadTranslations();
      });

      if (this.isNew) {
         this.contentBrick = await this.initializeNewEntity();
         this.originalStatus = this.contentBrick.entityStatus;
      } else {
         await this.loadContentBrickDefinition();
         await this.loadAttachments();
         filterStore.addVisitedItem(this.id, EntityType.ContentBrickDefinition);
      }
      ChangeWatcher.setupWatch(this, "contentBrick");

      if (this.$route.params.restoreTab) {
         this.selectedTab = this.$route.params.restoreTab;
      }
   }

   beforeDestroy() {
      this.$root.isDomainChangeDisabled = false;
   }

   unmounted() {
      ChangeWatcher.destroyWatch(this, "contentBrick");
   }
}
</script>
<style scoped></style>
