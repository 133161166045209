<template>
   <v-dialog v-model="showDialog" v-if="showDialog" content-class="test-report-dialog" persistent scrollable>
      <v-card>
         <v-card-title>
            {{ translateKey("taskDetail.testReportDialog.title", translations) }} - {{ taskTestReport?.taskCode }}
            <status-chip
               v-if="taskTestReport && taskTestReport.currentTaskStatus"
               class="ml-3"
               :value="taskTestReport.currentTaskStatus"
            ></status-chip>
            <v-spacer></v-spacer>
            <v-btn class="red--text ml-1" icon v-shortkey.once="['esc']" @shortkey="clickClose" @click="clickClose">
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </v-card-title>
         <v-card-text>
            <v-form ref="form">
               <div v-for="testResult in testResults" :key="testResult.nodeId" class="mb-5">
                  <h6 class="sticky-header">
                     <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <v-badge overlap bottom color="transparent" offset-x="16" offset-y="16" :value="true">
                              <v-icon v-on="on" dense color="grey">{{ getContentBrickIcon(testResult) }}</v-icon>
                              <template v-slot:badge>
                                 <v-icon v-on="on" :color="getContionCheckColor(testResult)" size="16" class="check-bg">
                                    {{ getContionResultIcon(testResult) }}
                                 </v-icon>
                              </template>
                           </v-badge>
                        </template>
                        <span>{{ getResultTooltip(testResult) }}</span>
                     </v-tooltip>
                     {{ testResult.contentBrickName }}
                  </h6>
                  <v-expansion-panels v-if="showDescription(testResult)" flat class="pl-1 mt-1">
                     <v-expansion-panel>
                        <v-expansion-panel-header class="pa-0 expansion-header-small">
                           <h6>
                              {{ descriptionHeader }}
                           </h6>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="description-panel">
                           <div
                              class="px-3 pt-3"
                              v-html="referencedTextAreas[testResult.contentBrickDescriptionId]?.value"
                           ></div>
                        </v-expansion-panel-content>
                     </v-expansion-panel>
                  </v-expansion-panels>
                  <task-test-report-field-group
                     class="pl-2 pr-1"
                     :value="testResult.resultTree"
                     :readonly="!canEdit"
                     :units="units"
                     :translations="translations"
                     :attachments="attachments"
                     :downloading="downloading"
                     :mediaUrls="mediaUrls"
                     :mediaUrlsUpdated="mediaUrlsUpdated"
                     :referencedLists="referencedLists"
                     :referencedTextAreas="referencedTextAreas"
                     @download-attachment="onDownloadAttachmentClick(testResult, ...arguments)"
                     @download-attachmentsUrls="downloadAttachmentsUrls(testResult, ...arguments)"
                     @edit-manual-result-note="onEditManualResultNote($event)"
                     @manual-result-changed="onManualResultChanged"
                  ></task-test-report-field-group>
                  <v-text-field
                     v-if="testResult?.resultTree?.recursiveResult === false"
                     :readonly="!canEdit"
                     v-model="testResult.resultNote"
                     :label="translateKey('taskDetail.testReportDialog.testResultNote', translations)"
                     :rules="resultNoteRules"
                     clearable
                  ></v-text-field>
               </div>
            </v-form>
         </v-card-text>
         <v-card-actions>
            <template>
               <v-chip v-if="passed === true" color="green" text-color="white">
                  {{ translateKey("DesignGuidelineResultType.Passed", translations) }}
               </v-chip>
               <v-chip v-else-if="passed === false" color="red" text-color="white">
                  {{ translateKey("DesignGuidelineResultType.Failed", translations) }}
               </v-chip>
               <v-chip v-else>{{ translateKey("DesignGuidelineResultType.Unevaluated", translations) }}</v-chip>
               {{ passedMessage }}
            </template>
            <v-spacer></v-spacer>
            <v-btn
               color="error"
               v-shortkey.once="['ctrl', 's']"
               @shortkey="onSaveButtonClick()"
               @click="onSaveButtonClick()"
               :loading="saving"
            >
               {{ saveButtonText }}
               <v-icon v-if="showSaveButton" class="ml-1">mdi-content-save</v-icon>
            </v-btn>
         </v-card-actions>
      </v-card>
      <field-manual-result-note-dialog
         v-if="selectedNodeResult"
         v-model="selectedNodeResult.manualResultNote"
         :showDialog.sync="showManualResultNoteModal"
         :fieldName="selectedNodeResult.name"
         :translations="translations"
      ></field-manual-result-note-dialog>
   </v-dialog>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import {
   AttachmentDownloadModel,
   AttachmentMetadata,
   IUnit,
   ProjectApi,
   ProjectContentBrickField,
   TaskDesignGuidelineReport,
   DesignGuidelineNodeResult,
   TranslationPublicModel,
   List,
   TextArea,
   DataModelFieldMetaData,
} from "@backend/api/pmToolApi";
import BaseResponse from "@models/BaseResponse";
import ConfirmCloseDialogResult from "@models/shared/ConfirmCloseDialogResult";
import SharedLookup from "@utils/SharedLookup";
import AttachmentUtils from "@utils/AttachmentUtils";
import DataModelFieldUtils from "@utils/DataModelFieldUtils";
import { ContentBrickTypeDecorator } from "@models/shared/ContentBrickTypeDecorator";
import DesignGuidelineResultDecorator from "@models/shared/DesignGuidelineResultDecorator";
import TaskTestReportFieldGroup from "@components/Tasklist/TaskDetailComponents/TestReport/task-test-report-field-group.vue";
import TestResultTree, { TestResultTreeNode } from "@models/testReport/TestResultTree";
import FieldManualResultNoteDialog from "@components/Tasklist/Shared/field-manual-result-note-dialog.vue";
import StatusChip from "@components/Tasklist/TaskDetailComponents/status-chip.vue";
import TaskUtils from "@utils/TaskUtils";

@Component({
   components: {
      StatusChip,
      TaskTestReportFieldGroup,
      FieldManualResultNoteDialog,
   },
})
export default class TaskTestReportDialog extends ComponentBase {
   @Prop({ required: true })
   units: SharedLookup<IUnit>;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   referencedLists: { [key: string]: List } | undefined;

   @Prop({ required: true })
   referencedTextAreas: { [key: string]: TextArea } | undefined;

   @Prop({ required: true })
   attachments: { [key: string]: AttachmentMetadata };

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   resolve: (result: ConfirmCloseDialogResult) => void;
   showDialog: boolean = false;
   saving: boolean = false;
   taskTestReport: TaskDesignGuidelineReport | null = null;
   testResults: TestResultTree[] = [];
   hasChangedManualResult = false;

   get canEdit(): boolean {
      return !this.readonly && !this.saving;
   }

   getContentBrickIcon(testResult: TestResultTree): string | undefined {
      return ContentBrickTypeDecorator.get(testResult?.contentBrickType).icon;
   }

   getContionResultIcon(testResult: TestResultTree): string | undefined {
      return DesignGuidelineResultDecorator.get(testResult?.resultTree?.recursiveResult ?? undefined).icon;
   }

   getContionCheckColor(testResult: TestResultTree): string | undefined {
      return DesignGuidelineResultDecorator.get(testResult?.resultTree?.recursiveResult ?? undefined).color;
   }

   getResultTooltip(testResult: TestResultTree): string {
      const translationKey = DesignGuidelineResultDecorator.get(
         testResult?.resultTree?.recursiveResult ?? undefined
      ).translationKey;
      return this.translateKey(translationKey, this.translations);
   }

   setTestReport(taskTestReport: TaskDesignGuidelineReport | null) {
      if (taskTestReport) {
         const resultTree = (taskTestReport.designGuidelineResults ?? []).map((tr) => new TestResultTree(tr));
         this.testResults = taskTestReport.designGuidelineResults = resultTree;
      } else {
         this.testResults = [];
      }

      this.taskTestReport = taskTestReport;
   }

   get passed(): boolean | null {
      return (
         this.testResults.reduce(
            (acc, curr) =>
               TestResultTreeNode.getResultPriority(curr.resultTree.recursiveResult) >
               TestResultTreeNode.getResultPriority(acc)
                  ? curr.resultTree.recursiveResult
                  : acc,
            undefined
         ) ?? null
      );
   }

   get passedMessage(): string {
      if (this.passed == true) {
         return this.translateKey("taskDetail.testReportDialog.passedDescription", this.translations);
      } else if (this.passed == false) {
         return this.translateKey("taskDetail.testReportDialog.failedDescription", this.translations);
      } else {
         return this.translateKey("taskDetail.testReportDialog.unevaluatedDescription", this.translations);
      }
   }

   //--------------- Summary ---------------
   showDescription(testResult: TestResultTree): boolean {
      return !!testResult.contentBrickDescriptionId && !!this.referencedTextAreas;
   }

   get descriptionHeader(): string {
      return this.translateKey("taskDetail.testReportDialog.contentBrickDescriptionLabel", this.translations);
   }

   // ----------- ManualResultNote editor ------------------
   showManualResultNoteModal: boolean = false;
   selectedNodeResult: DesignGuidelineNodeResult | null = null;

   onEditManualResultNote(event: {
      field: ProjectContentBrickField;
      designGuidelineNodeResult: DesignGuidelineNodeResult;
   }) {
      this.selectedNodeResult = event.designGuidelineNodeResult;
      this.showManualResultNoteModal = true;
   }

   // ----------- Dialog control ------------------
   async showDialogAsync(testReport: TaskDesignGuidelineReport): Promise<ConfirmCloseDialogResult> {
      return new Promise<ConfirmCloseDialogResult>(async (resolve) => {
         // short 'top half' to prevent UI freezing
         this.resolve = resolve;
         this.setTestReport(testReport);
         this.showDialog = true;
      });
   }

   hideDialog() {
      this.showDialog = false;
   }

   clickClose() {
      this.resolve && this.resolve(ConfirmCloseDialogResult.Cancel);
      this.hasChangedManualResult = false;
      this.hideDialog();
   }

   get showSaveButton(): boolean {
      return !this.readonly || this.hasChangedManualResult;
   }

   get saveButtonText(): string {
      return this.showSaveButton
         ? this.translateKey("button.save", this.translations)
         : this.translateKey("button.close", this.translations);
   }

   async onSaveButtonClick(): Promise<void> {
      if (!this.showSaveButton) {
         this.clickClose();
         return;
      }

      if (!this.saving && this.validate()) {
         this.testResults.forEach((tr) => tr.writeResults());

         try {
            this.saving = true;
            await ProjectApi.updateTaskDesignGuidelineReport(
               this.taskTestReport!.projectId,
               this.taskTestReport!.taskId,
               this.taskTestReport!
            );
            this.resolve && this.resolve(ConfirmCloseDialogResult.Save);
         } catch (e) {
            let error = e as BaseResponse;
            console.log("API TaskContentBrick/TaskDesignGuidelineReport update error:", error);
            this.notifyError(error, "Update", "TaskDesignGuidelineReport");
            this.resolve && this.resolve(ConfirmCloseDialogResult.Cancel);
         } finally {
            this.saving = false;
            this.hasChangedManualResult = false;
            this.hideDialog();
         }
      }
   }

   onManualResultChanged() {
      this.hasChangedManualResult = true;
   }

   // ---------- Validation ------------
   resultNoteRules: Function[] = [(v) => !!v || "Note is required"];

   validate(): boolean {
      const res = this.$refs.form.validate();
      if (!res) {
         this.$nextTick(() => {
            const el = this.$refs.form.$el.querySelector(".v-messages.error--text .v-messages__message:first-of-type");
            const faultedInput = el?.closest(".v-input");
            faultedInput?.scrollIntoView({ behavior: "smooth", block: "center" });
         });
      }
      return res;
   }

   // ----------- ValueFields -----------------------
   getFieldUnits(field: ProjectContentBrickField) {
      var unitIds = DataModelFieldUtils.findReferencedUnitIds(field, field.value);
      return unitIds.map((uId) => this.units.cache[uId]);
   }

   // ----------- Attachments ------------------
   downloading: { [key: string]: boolean } = {};
   mediaUrls = {}; // ImageType Fields content
   mediaUrlsUpdated: { [key: string]: boolean } = {};
   mediaUrlSizes: { [key: string]: number | undefined } = {};

   async onDownloadAttachmentClick(
      testResult: TestResultTree,
      cbField: ProjectContentBrickField,
      blobName: string
   ): Promise<void> {
      const file = await this.downloadAttachment(cbField.id, blobName, testResult);
      if (file) {
         AttachmentUtils.downloadFile(file);
      }
   }

   async downloadAttachment(
      fieldNodeId: string,
      blobName: string,
      testResult: TestResultTree,
      size: number | null = null
   ): Promise<AttachmentDownloadModel | undefined> {
      if (!blobName) {
         throw "Blob name is required";
      }

      try {
         this.$set(this.downloading, fieldNodeId, true);
         const queryModelType = TaskUtils.getReportAttachmentModelType(testResult);

         // Call the API FileResponse
         let file = await ProjectApi.generateTaskAttachmentDownloadUrl(
            this.taskTestReport!.projectId,
            this.taskTestReport!.taskId,
            queryModelType,
            blobName,
            size
         );

         return file;
      } catch (e) {
         let error = e as BaseResponse;
         console.log("API Task attachment download error:", error);
         this.notifyError(error, "download", "Attachment");
      } finally {
         this.$set(this.downloading, fieldNodeId, false);
      }
   }

   async downloadAttachmentsUrls(
      testResult: TestResultTree,
      blobNames: string[],
      fieldMetadata: DataModelFieldMetaData,
      imageSize?: number
   ): Promise<void> {
      let mediaBlobsToDownload: string[] = [];

      if (blobNames) {
         blobNames.forEach((blob) => {
            const currentSize = this.mediaUrlSizes[blob];
            const isBigger = currentSize != undefined && (imageSize == undefined || imageSize > currentSize);
            if (!this.mediaUrls[blob] || isBigger) {
               mediaBlobsToDownload.push(blob);
            }
         });
      }

      try {
         for (const blobName of mediaBlobsToDownload) {
            var file = await this.downloadAttachment(fieldMetadata.id, blobName, testResult, imageSize ?? null);

            if (file && file.blobName) {
               this.$set(this.mediaUrls, file.blobName!, file.downloadUrl);
               this.$set(this.mediaUrlSizes, file.blobName!, imageSize);
            }
         }
      } finally {
         this.$set(this.mediaUrlsUpdated, fieldMetadata.id, true);
      }
   }
}
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";

@media #{map-get($display-breakpoints, 'lg-and-down')} {
   ::v-deep .v-dialog.test-report-dialog {
      width: 95%;

      /* unify font color - expansion panel and card each set a different font color */
      > .theme--light.v-card .v-card__subtitle,
      .theme--light.v-card > .v-card__text {
         color: rgba(0, 0, 0, 0.87);
      }
   }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
   ::v-deep .v-dialog.test-report-dialog {
      width: 75%;

      /* unify font color - expansion panel and card each set a different font color */
      > .theme--light.v-card .v-card__subtitle,
      .theme--light.v-card > .v-card__text {
         color: rgba(0, 0, 0, 0.87);
      }
   }
}

.sticky-header {
   position: sticky;
   top: -1px;
   z-index: 10;
   background: white;
   margin-bottom: 0;
   padding-bottom: 8px;
}
</style>
