<template>
   <v-row dense>
      <v-col class="pa-0" cols="12" v-if="value.descriptionId && referencedTextAreas">
         <v-expansion-panels flat class="mt-2">
            <v-expansion-panel>
               <v-expansion-panel-header class="pa-0 expansion-header-small">
                  <h6>{{ translateKey("taskDetail.testReportDialog.groupDescriptionLabel", translations) }}</h6>
               </v-expansion-panel-header>
               <v-expansion-panel-content class="description-panel">
                  <div class="pl-3" v-html="referencedTextAreas[value.descriptionId]?.value"></div>
               </v-expansion-panel-content>
            </v-expansion-panel>
         </v-expansion-panels>
      </v-col>
      <template v-if="hasVisibleContent">
         <v-col v-for="child in visibleChildren" cols="12" :key="child.nodeId" :class="getChildClass(child)">
            <v-expansion-panels
               v-if="child.type === DesignGuidelineNodeType.Group"
               accordion
               class="field-group"
               :value="0"
            >
               <v-expansion-panel :class="depthClass">
                  <v-expansion-panel-header class="pl-3" color="bg240">
                     <div class="d-inline-flex align-baseline">
                        <task-test-report-node-icon
                           :value="child"
                           :translations="translations"
                        ></task-test-report-node-icon>
                        <span class="ml-1">{{ child.name }}</span>
                        <task-test-report-node-result
                           v-if="shouldShowResult(child)"
                           class="ml-auto"
                           :value="child"
                           :translations="translations"
                           @click.stop.prevent
                           @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                        ></task-test-report-node-result>
                     </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                     <task-test-report-field-group
                        :value="child"
                        :translations="translations"
                        :readonly="readonly"
                        :units="units"
                        :depth="depth + 1"
                        :attachments="attachments"
                        :downloading="downloading"
                        :mediaUrls="mediaUrls"
                        :mediaUrlsUpdated="mediaUrlsUpdated"
                        :referencedLists="referencedLists"
                        :referencedTextAreas="referencedTextAreas"
                        @download-attachment="$emit('download-attachment', ...arguments)"
                        @download-attachmentsUrls="$emit('download-attachmentsUrls', ...arguments)"
                        @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                        @manual-result-changed="$emit('manual-result-changed')"
                     ></task-test-report-field-group>
                  </v-expansion-panel-content>
               </v-expansion-panel>
            </v-expansion-panels>
            <div v-else class="d-flex flex-row align-baseline">
               <task-test-report-node-icon :value="child" :translations="translations"></task-test-report-node-icon>
               <field-value
                  :value="child.value"
                  :readonly="true"
                  :loading="isAttachmentLoading(child.field)"
                  :type="child.field.definition.type"
                  :name="child.field.name"
                  :units="getFieldUnits(child.field)"
                  :list="referencedLists[child.field.definition.listId]"
                  :field="child.field"
                  :attachments="attachments"
                  :mediaUrlsValues="mediaUrls"
                  :mediaUrlsUpdated="mediaUrlsUpdated"
                  @download-attachment="$emit('download-attachment', $event, child.field)"
                  @download-attachmentsUrls="$emit('download-attachmentsUrls', ...arguments)"
                  :regularExpressions="[]"
                  :designGuidelineNodeResult="child"
                  :translations="translations"
                  class="flex-grow-1 field-value w-100"
               ></field-value>
               <task-test-report-node-result
                  v-if="shouldShowResult(child)"
                  class="pt-3 align-self-start"
                  :value="child"
                  :translations="translations"
                  @click.stop.prevent
                  @edit-manual-result-note="$emit('edit-manual-result-note', $event)"
                  @manual-result-changed="$emit('manual-result-changed')"
               ></task-test-report-node-result>
               <div v-else style="width: 225px"></div>
            </div>
         </v-col>
      </template>
      <span v-else class="font-italic">
         {{ translateKey(hasContent ? "taskDetail.noVisibleContent" : "taskDetail.noContent", translations) }}
      </span>
   </v-row>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import ComponentBase from "@components/Shared/Base/component-base.vue";
import FieldValue from "@components/ContentBricks/Shared/field-value.vue";
import {
   List,
   IUnit,
   TextArea,
   AttachmentMetadata,
   ProjectContentBrickField,
   TranslationPublicModel,
   DesignGuidelineNodeType,
   DataModelFieldMetaData,
   DesignGuidelineFieldEvaluation,
} from "@backend/api/pmToolApi";
import AttachmentUtils from "@utils/AttachmentUtils";
import DataModelFieldUtils from "@utils/DataModelFieldUtils";
import SharedLookup from "@utils/SharedLookup";
import { Guid } from "guid-typescript";
import { TestResultTreeNode } from "@models/testReport/TestResultTree";
import TaskTestReportNodeIcon from "@components/Tasklist/TaskDetailComponents/TestReport/task-test-report-node-icon.vue";
import TaskTestReportNodeResult from "@components/Tasklist/TaskDetailComponents/TestReport/task-test-report-node-result.vue";

@Component({
   name: "TaskTestReportFieldGroup",
   components: { FieldValue, TaskTestReportNodeIcon, TaskTestReportNodeResult },
})
export default class TaskTestReportFieldGroup extends ComponentBase {
   @Prop({ required: true })
   value: TestResultTreeNode;

   @Prop({ default: false })
   readonly: boolean;

   @Prop({ required: true })
   units: SharedLookup<IUnit>;

   @Prop({ required: true })
   referencedLists: { [key: string]: List } | undefined;

   @Prop({ required: true })
   referencedTextAreas: { [key: string]: TextArea } | undefined;

   @Prop({ default: 0 })
   depth: number;

   @Prop({ required: true })
   translations: TranslationPublicModel[];

   // ----- attachments props ----
   @Prop({ required: true })
   attachments: { [key: string]: AttachmentMetadata } | undefined;

   @Prop({ required: true })
   downloading: { [key: string]: boolean };

   @Prop({ required: true })
   mediaUrls: object;

   @Prop({ required: true })
   mediaUrlsUpdated: { [key: string]: boolean };

   DesignGuidelineNodeType = DesignGuidelineNodeType;

   get allChildren(): TestResultTreeNode[] {
      return this.value.children;
   }

   get hasContent(): boolean {
      return !!this.allChildren.length || this.hasDescription;
   }

   get hasDescription(): boolean {
      if (!this.value || !this.value.descriptionId || this.value.descriptionId === Guid.EMPTY) {
         return false;
      }
      return !!this.referencedTextAreas![this.value.descriptionId].value;
   }

   get visibleChildren(): TestResultTreeNode[] {
      return this.allChildren
         .filter((n) => n.isVisible)
         .sort((a, b) => {
            return (a.field?.definition?.order ?? 0) - (b.field?.definition?.order ?? 0); // sort by order
         });
   }

   get hasVisibleContent(): boolean {
      return !!this.visibleChildren.length || this.hasDescription;
   }

   get depthClass(): string {
      return `depth-${this.depth % 4}`;
   }

   getChildClass(node: TestResultTreeNode) {
      return {
         "pa-0": true,
         "field-group-child-field": node.type !== DesignGuidelineNodeType.Group,
         "field-group-child-group": node.type === DesignGuidelineNodeType.Group,
      };
   }

   // ------------- Group Result icon ----------------
   shouldShowResult(testResult: TestResultTreeNode): boolean {
      return testResult.hasCondition && testResult.evaluationType !== DesignGuidelineFieldEvaluation.Disabled;
   }

   // ----------- ValueFields -----------------------
   getFieldUnits(field: ProjectContentBrickField) {
      var unitIds = DataModelFieldUtils.findReferencedUnitIds(field, field.value);
      return unitIds.map((uId) => this.units.cache[uId]);
   }

   // ----------- Attachments ------------------
   isAttachmentLoading(field: DataModelFieldMetaData): boolean {
      if (!AttachmentUtils.isAttachmentField(field.definition?.type)) return false;

      return !!this.downloading[field.id];
   }
}
</script>
<style scoped lang="scss">
::v-deep.manual-result-note-btn.v-btn--icon.v-size--small {
   height: 24px;
   width: 24px;
}
</style>
